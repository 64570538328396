// Gatsby supports TypeScript natively!
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
// import { StaticImage } from "gatsby-plugin-image"

/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";

const pricing = {
  tiers: [
    {
      title: "Commerical (Non Standard Vehicle)",
      price: 160,
      frequency: "/Hour",
      description: "",
      features: [
        "4 Hour Minimum",
        "Hourly Rate Includes 2 Workers",
      ],
      cta: "Call for Quote",
      mostPopular: false,
    },
    {
      title: "Fleet",
      price: "Call us",
      frequency: "/TDB",
      description: "",
      features: [
        "Standard Vehicle Services Offered",
      ],
      cta: "Call for Quote",
      mostPopular: false,
    },
  ],
};

const features = [
  {
    name: "Fleet Vehicles",
  },
  {
    name: "Semi-Trailer Trucks",
  },
  {
    name: "Concrete Removal",
  },
  {
    name: "Many More (Give Us A Call)",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <Layout>
      <Seo title="Commercial" description="Commercial Automotive Detailing. We offer a range of services for Fleet Vehicles, Semi-Trailer Trucks, Concrete Removal, and Many More (Give Us A Call)" />

      <div className="py-4 bg-white overflow-hidden lg:py-4 text-gray-600">
        <div className="text-center max-w-4xl mx-auto px-4 py-4 sm:px-6 sm:pt-20 sm:pb-4 lg:max-w-7xl lg:pt-12 lg:px-8 pb-16">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="text-3xl font-extrabold text-gray-900">
                  Commercial Services
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  We Customize Our Automotive Services to Meet and Exceed Your Needs For:
                </p>
              </div>
              <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <CheckIcon
                        className="absolute h-6 w-6 text-green-500"
                        aria-hidden="true"
                      />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                        {feature.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-9 text-base text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>


      {/* Tiers */}
      <div className="max-w-4xl mx-auto mt-6 space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-2 pb-16">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">
                {tier.title}
              </h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-green-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">
                  ${tier.price}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  {tier.frequency}
                </span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className="flex-shrink-0 w-6 h-6 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            {/* CTA Disable
            <a
              href="tel:877-578-3265"
              className={classNames(
                tier.mostPopular
                  ? "bg-green-500 text-white hover:bg-green-600"
                  : "bg-green-50 text-green-700 hover:bg-green-100",
                "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
              )}
            >
              {tier.cta}
            </a>
            */}
          </div>
        ))}
      </div>
    </Layout>
  );
}
